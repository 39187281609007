/* You can add global styles to this file, and also import other style files */
@dashboard_color: #1A237E;
@licence-color: #0277BD;
@reportAnalysis-color: #3f51b5;
@reportAnalysis-color-v2: #3f51b5;
@viser-download: #2E7D32;
@docs-color: #e84e40;
@audio-color: #607D8B;
@mos-rating-only-errors: #E53935;
@mos-rating-with-errors: #E68900;
@mos-rating-no-error: #7DB043;
@download: #2E7D32;
@header-height: 42px;
@smartviser-blue: #194ba0;

.audio-color {
    color: @audio-color;
}

.dashboard-color {
color: @dashboard_color !important;
}

.documentation-color {
    color: @docs-color;
}

.download-color {
    color: @download !important;
}

.licence-color {
    color: @licence-color;
}

.report-analysis-color {
    color: @reportAnalysis-color;
}

.report-analysis-color-v2 {
    color: @reportAnalysis-color-v2 !important;
}
.mos-rating-only-errors{
    color:@mos-rating-only-errors;
}
.mos-rating-with-errors{
    color:@mos-rating-with-errors;
}
.mos-rating-no-error{
    color:@mos-rating-no-error;
}

.column-100{
    display: flex;
    flex-direction: column;
    width: 99.5%;
}

.column-80{
    display: flex;
    flex-direction: column;
    width: 80%;
}

.row-100{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.width-10{
    width: 10%;
}

.width-20{
    width: 20%;
}

.width-100{
    width: 100%;
}

.width-90{
    width: 90%;
}

.width-80{
    width: 80%;
}

.column{
    display: flex;
    flex-direction: column;
}

.row{
    display: flex;
    flex-direction: row;
}

.icon-test-ok {
    background: url('/assets/icons/testPlanOK.svg');
    height: 24px;
    width: 24px;
    display: block;
  }
  
  .icon-test-ko {
    background: url('/assets/icons/testPlanKO.svg');
    height: 24px;
    width: 24px;
    display: block;
  }

.mdc-tab {
    max-width: 220px;
}

.mat-mdc-header-cell{
    font-size: .9rem;
    color: #1a4ea6;
    font-weight: bold !important;
}

.mat-mdc-row{
    font-weight: normal !important;
}

html, .body{
    margin: 0px;
    background-color: #efefef;
    font-family: Roboto, Arial, sans-serif;
    height: 100vh;
    .main-container{
        height: calc(100vh - 45px);
    }
}

.mat-drawer{
    padding-left: 10px;
}

.mat-drawer-container{
    padding-left: 5px;
    overflow: auto;
}

.create-button{
    margin-top: 20px;
    margin-right: 10px;
  }

  .licence-button{
    margin-top: 12px;
    margin-left: 10px;
  }
  
  .margin-tab{
    display: flex;
    margin-left: 10px;
  }

.heigth-100{
    height: calc(100vh - 45px);
  }